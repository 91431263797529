.advancedDiagnostics {
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  overflow-y: auto;
  background-color: #f8f9fa;
  color: #333;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.container {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.25rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  margin-bottom: 1.5rem;
  border: 1px solid #dee2e6;
}

.container h3 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  color: #343a40;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 0.5rem;
}

.header {
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 1rem;
}

.header h2 {
  margin: 0 0 0.5rem 0;
  font-weight: 600;
  color: #2c3e50;
}

.header p {
  margin: 0;
  color: #6c757d;
  font-size: 0.9rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.formSection {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.25rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.formSection h3, .formSection h4 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  color: #343a40;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 0.5rem;
}

.formSection h4 {
  font-size: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
  border-bottom: none;
  padding-bottom: 0;
}

.formField {
  margin-bottom: 1rem;
}

.formField label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 0.9rem;
  color: #495057;
}

.formField input[type="text"],
.formField textarea,
.formField select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 0.95rem;
  background-color: white;
  transition: border-color 0.2s;
}

.formField input[type="text"]:focus,
.formField textarea:focus,
.formField select:focus {
  border-color: #6997e0;
  outline: none;
  box-shadow: 0 0 0 3px rgba(105, 151, 224, 0.1);
}

.checkboxGroup, .radioGroup {
  margin-bottom: 1rem;
}

.checkboxGroup p {
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 0.9rem;
  color: #495057;
}

.checkboxGroup label,
.radioGroup label,
.checkbox label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  cursor: pointer;
  font-size: 0.9rem;
}

.checkboxGroup input[type="checkbox"],
.radioGroup input[type="radio"],
.checkbox input[type="checkbox"] {
  margin-right: 0.5rem;
  cursor: pointer;
}

.inputWithSlider {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.confidenceSlider {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.confidenceSlider label {
  font-size: 0.85rem;
  color: #495057;
}

.previewSection {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.25rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.promptPreview {
  background-color: #f1f3f5;
  border: 1px solid #e9ecef;
  border-radius: 0.25rem;
  padding: 1rem;
  white-space: pre-wrap;
  font-family: monospace;
  font-size: 0.85rem;
  color: #212529;
  max-height: 150px;
  overflow-y: auto;
}

.formActions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.submitButton {
  background-color: #006acc;
  color: white;
  border: none;
  border-radius: 0.25rem;
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submitButton:hover {
  background-color: #0055b3;
}

.submitButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 106, 204, 0.3);
}

@media (max-width: 768px) {
  .advancedDiagnostics {
    padding: 1rem;
  }
  
  .formSection,
  .previewSection {
    padding: 1rem;
  }
}
