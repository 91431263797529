.mainLayout {
  display: grid;
  grid-template-areas:
    "header header header"
    "left content right"
    "footer footer footer";
  grid-template-columns: var(--left-panel-width) 1fr var(--right-panel-width);
  grid-template-rows: auto 1fr auto;
  height: 100vh;
  width: 100%;
  background-color: var(--color-bg-primary);
}

.header {
  grid-area: header;
  z-index: var(--z-index-header);
  background-color: var(--color-bg-header);
  color: var(--color-text-primary);
}

.leftPanel {
  grid-area: left;
  border-right: 1px solid var(--color-border-faint);
  background-color: var(--color-bg-secondary);
  overflow-y: auto;
  color: var(--color-text-primary);
}

.content {
  grid-area: content;
  position: relative;
  overflow: hidden;
  background-color: var(--color-bg-canvas);
  color: var(--color-content-text-primary);
}

.rightPanel {
  grid-area: right;
  border-left: 1px solid var(--color-border-faint);
  background-color: var(--color-bg-secondary);
  overflow-y: auto;
  color: var(--color-text-primary);
}

.footer {
  grid-area: footer;
  border-top: 1px solid var(--color-border-faint);
  background-color: var(--color-bg-secondary);
}

.futureCompartment {
  height: 40px;
  border-bottom: 1px solid var(--color-border-faint);
  background-color: var(--color-bg-medium);
}

.contentArea {
  flex: 1;
  overflow: hidden;
  position: relative;
}
