.imageView {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.canvasBoundary {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 0.3s ease-in-out;
}

.mainImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.mainImage.visible {
  opacity: 1;
}

.transitioning {
  transform: translateX(-100%);
} 