.profile-setup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #0a0a0a;
  padding: 20px;
}

.profile-setup-card {
  background-color: rgba(35, 35, 35, 0.8);
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  padding: 40px;
  width: 100%;
  max-width: 600px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.profile-setup-card h2 {
  color: rgba(255, 255, 255, 0.9);
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 600;
}

.profile-setup-card p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  margin-bottom: 30px;
}

.progress-bar {
  height: 6px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  margin-bottom: 10px;
  overflow: hidden;
}

.progress-bar-fill {
  height: 100%;
  background-color: rgba(224, 80, 70, 0.8);
  transition: width 0.3s ease;
}

.step-indicator {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  margin-bottom: 30px;
}

.form-step {
  text-align: left;
  margin-bottom: 30px;
}

.form-step h3 {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 500;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-weight: 500;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 12px;
  background-color: rgba(25, 25, 25, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
  transition: all 0.2s ease;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: rgba(224, 80, 70, 0.5);
  box-shadow: 0 0 0 2px rgba(224, 80, 70, 0.2);
}

.form-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.back-btn,
.next-btn,
.submit-btn {
  padding: 12px 24px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.back-btn {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

.back-btn:hover:not(:disabled) {
  background-color: rgba(255, 255, 255, 0.05);
}

.next-btn,
.submit-btn {
  background-color: rgba(224, 80, 70, 0.8);
  border: none;
  color: rgba(255, 255, 255, 0.9);
}

.next-btn:hover:not(:disabled),
.submit-btn:hover:not(:disabled) {
  background-color: rgba(224, 80, 70, 1);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.next-btn:active:not(:disabled),
.submit-btn:active:not(:disabled) {
  transform: translateY(0);
}

.back-btn:disabled,
.next-btn:disabled,
.submit-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.error-message {
  background-color: rgba(255, 100, 100, 0.2);
  color: rgba(255, 180, 180, 1);
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 20px;
  font-size: 14px;
  text-align: center;
}

.form-summary {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 20px;
  margin-top: 30px;
}

.form-summary h4 {
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 500;
}

.form-summary p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  margin-bottom: 10px;
  text-align: left;
}

/* Convert kebab-case to camelCase for CSS modules */
.profileSetupContainer {
  composes: profile-setup-container;
}

.profileSetupCard {
  composes: profile-setup-card;
}

.progressBar {
  composes: progress-bar;
}

.progressBarFill {
  composes: progress-bar-fill;
}

.stepIndicator {
  composes: step-indicator;
}

.formStep {
  composes: form-step;
}

.formGroup {
  composes: form-group;
}

.formNavigation {
  composes: form-navigation;
}

.backBtn {
  composes: back-btn;
}

.nextBtn {
  composes: next-btn;
}

.submitBtn {
  composes: submit-btn;
}

.errorMessage {
  composes: error-message;
}

.formSummary {
  composes: form-summary;
}
