.login-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(10, 10, 10, 0.7); /* Keep dark overlay */
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--z-index-modal);
  padding: var(--spacing-md);
}

.login-modal {
  background-color: var(--color-bg-secondary);
  border-radius: var(--radius-lg);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  padding: var(--spacing-xxxl);
  width: 100%;
  max-width: 500px;
  text-align: center;
  border: 1px solid var(--color-border-faint);
  max-height: 90vh;
  overflow-y: auto;
  scrollbar-width: thin;
  position: relative;
}

.login-modal::-webkit-scrollbar {
  width: 6px;
}

.login-modal::-webkit-scrollbar-track {
  background: var(--color-bg-hover);
}

.login-modal::-webkit-scrollbar-thumb {
  background-color: var(--color-text-tertiary);
  border-radius: 3px;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: var(--color-text-secondary);
  font-size: 24px;
  cursor: pointer;
  transition: color var(--duration-fast) var(--easing-standard);
}

.close-button:hover {
  color: var(--color-text-primary);
}

.login-modal h2 {
  color: var(--color-text-primary);
  font-size: var(--font-size-2xl);
  margin-bottom: var(--spacing-xs);
  font-weight: var(--font-weight-semibold);
}

.login-modal p {
  color: var(--color-text-secondary);
  font-size: var(--font-size-lg);
  margin-bottom: var(--spacing-xxl);
}

.login-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: var(--spacing-lg);
  border-bottom: 1px solid var(--color-border-faint);
}

.tab-button {
  background: none;
  border: none;
  color: var(--color-text-tertiary);
  font-size: var(--font-size-lg);
  padding: var(--spacing-xs) var(--spacing-sm);
  margin: 0 var(--spacing-xs);
  cursor: pointer;
  transition: all var(--duration-fast) var(--easing-standard);
  position: relative;
}

.tab-button:hover {
  color: var(--color-text-primary);
}

.tab-button.active {
  color: var(--color-primary);
  font-weight: var(--font-weight-medium);
}

.tab-button.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: var(--color-primary);
}

.login-form {
  margin-bottom: var(--spacing-lg);
  text-align: left;
}

.form-group {
  margin-bottom: var(--spacing-lg);
}

.form-group label {
  display: block;
  margin-bottom: var(--spacing-xs);
  color: var(--color-text-primary);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
}

.form-group input,
.form-group select {
  width: 100%;
  padding: var(--spacing-sm);
  background-color: #f5f5f7;
  border: 1px solid #d2d2d7;
  border-radius: var(--radius-sm);
  border-radius: 0;
  color: var(--color-text-primary);
  font-size: var(--font-size-base);
  transition: all var(--duration-fast) var(--easing-standard);
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-bottom-color: var(--color-primary);
  box-shadow: none;
}

.form-help-text {
  font-size: var(--font-size-sm);
  color: var(--color-text-tertiary);
  margin-top: var(--spacing-xs);
  margin-bottom: 0;
}

.login-btn {
  width: auto;
  padding: var(--spacing-sm) var(--spacing-xl);
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--radius-full);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--duration-fast) var(--easing-standard);
  margin-top: var(--spacing-xl);
  display: block;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 0.5px;
}

.login-btn:hover:not(:disabled) {
  background-color: var(--color-primary-hover);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.login-btn:active:not(:disabled) {
  transform: translateY(0);
}

.login-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.error-message {
  background-color: rgba(255, 70, 70, 0.1);
  color: rgb(220, 53, 53);
  padding: var(--spacing-xs);
  border-radius: var(--radius-md);
  margin-bottom: var(--spacing-lg);
  font-size: var(--font-size-base);
  text-align: center;
}

.success-message {
  background-color: rgba(46, 196, 135, 0.1);
  color: rgb(46, 160, 111);
  padding: var(--spacing-sm);
  border-radius: var(--radius-md);
  margin-bottom: var(--spacing-lg);
  font-size: var(--font-size-base);
  text-align: center;
  line-height: var(--line-height-normal);
}

.login-footer {
  margin-top: var(--spacing-xxl);
  padding-top: var(--spacing-lg);
  border-top: 1px solid var(--color-border-faint);
}

.login-footer p {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-sm);
  margin: 0;
}

/* Profile fields section */
.profile-fields {
  padding: var(--spacing-md) 0;
  margin: var(--spacing-lg) 0;
  border-top: 1px solid var(--color-border-faint);
  border-bottom: 1px solid var(--color-border-faint);
}

.profile-fields h3 {
  color: var(--color-text-primary);
  font-size: var(--font-size-lg);
  margin-bottom: var(--spacing-sm);
  text-align: center;
  border-bottom: 1px solid var(--color-border-faint);
  padding-bottom: var(--spacing-xs);
}

.form-group select {
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="6" viewBox="0 0 12 6"><path fill="rgba(0,0,0,0.5)" d="M0 0l6 6 6-6z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 12px center;
}

.form-group select option {
  background-color: var(--color-bg-secondary);
  color: var(--color-text-primary);
}
