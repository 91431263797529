.admin-panel {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 20px;
  color: rgba(255, 255, 255, 0.9);
  height: calc(100vh - 60px); /* Subtract header height */
  overflow-y: auto;
}

.admin-panel h1 {
  font-size: 28px;
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 15px;
}

.admin-panel h2 {
  font-size: 22px;
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 0.9);
}

.admin-panel h3 {
  font-size: 18px;
  margin-bottom: 15px;
  color: rgba(255, 255, 255, 0.9);
}

.admin-tabs {
  display: flex;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.admin-tabs .tab-button {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}

.admin-tabs .tab-button:hover {
  color: rgba(255, 255, 255, 0.9);
}

.admin-tabs .tab-button.active {
  color: rgba(224, 80, 70, 0.9);
  font-weight: 500;
}

.admin-tabs .tab-button.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: rgba(224, 80, 70, 0.9);
}

.admin-section {
  margin-bottom: 40px;
}

.admin-card {
  background-color: rgba(35, 35, 35, 0.5);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.admin-error {
  background-color: rgba(255, 100, 100, 0.2);
  color: rgba(255, 180, 180, 1);
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 20px;
  font-size: 14px;
}

.admin-success {
  background-color: rgba(46, 196, 135, 0.2);
  color: rgba(46, 196, 135, 0.9);
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 20px;
  font-size: 14px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-weight: 500;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 12px;
  background-color: rgba(25, 25, 25, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
  transition: all 0.2s ease;
}

.form-group textarea {
  min-height: 80px;
  resize: vertical;
}

.form-help {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 6px;
  line-height: 1.4;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: rgba(224, 80, 70, 0.5);
  box-shadow: 0 0 0 2px rgba(224, 80, 70, 0.2);
}

.form-row {
  display: flex;
  gap: 20px;
}

.form-row .form-group {
  flex: 1;
}

.admin-button {
  padding: 12px 20px;
  background-color: rgba(224, 80, 70, 0.8);
  color: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.admin-button:hover:not(:disabled) {
  background-color: rgba(224, 80, 70, 1);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.admin-button:active:not(:disabled) {
  transform: translateY(0);
}

.admin-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.admin-table-container {
  overflow-x: auto;
}

.admin-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.admin-table th,
.admin-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.admin-table th {
  background-color: rgba(25, 25, 25, 0.5);
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  font-size: 14px;
}

.admin-table tr:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.admin-table code {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 3px 6px;
  border-radius: 4px;
  font-family: monospace;
  font-size: 13px;
}

.table-description {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 4px;
}

.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.status-badge.active {
  background-color: rgba(46, 196, 135, 0.2);
  color: rgba(46, 196, 135, 0.9);
}

.status-badge.inactive {
  background-color: rgba(255, 100, 100, 0.2);
  color: rgba(255, 180, 180, 1);
}

.table-button {
  padding: 4px 8px;
  background-color: rgba(59, 130, 246, 0.8);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.table-button:hover {
  background-color: rgba(59, 130, 246, 1);
}
