.rightPanel {
  height: 100%;
  background-color: var(--color-bg-secondary);
  border-left: 1px solid var(--color-border-faint);
  overflow-y: auto;
}

.contentSection {
  padding: 24px 20px;
}

.sectionHeader {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-text-primary);
  margin-bottom: 20px;
  letter-spacing: -0.2px;
  /* Added line to force update */
  text-transform: none;
}

.recentReportsSection {
  margin-top: 8px;
}

.loadingHistory {
  color: var(--color-text-secondary);
  text-align: center;
  padding: 20px 0;
  font-size: 13px;
  letter-spacing: -0.2px;
}

.reportsList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.reportItem {
  padding: 12px 14px;
  background-color: transparent;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.15s ease;
}

.reportItem:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.reportItemContent {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.reportTitle {
  font-size: 13px;
  font-weight: 500;
  color: var(--color-text-primary);
  letter-spacing: -0.1px;
}

.reportTimestamp {
  font-size: 12px;
  color: var(--color-text-secondary);
  letter-spacing: 0;
}

.emptyHistory {
  text-align: center;
  padding: 40px 0;
  color: var(--color-text-secondary);
  font-size: 13px;
  letter-spacing: -0.2px;
}

.emptyHistoryHint {
  font-size: 12px;
  margin-top: 8px;
  color: var(--color-text-tertiary);
  letter-spacing: 0;
}