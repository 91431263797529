:root {
  /* Colors */
  --color-bg-primary: #1d1d1f; /* Professional dark gray for content area */
  --color-bg-secondary: #FFFFFF; /* Pure white for panels/header/footer */
  --color-bg-header: #FFFFFF; /* Pure white for header */
  --color-bg-hover: rgba(0, 0, 0, 0.03); /* Very subtle light gray hover for white */
  --color-bg-active: rgba(0, 0, 0, 0.06); /* Slightly darker active for white */
  --color-bg-medium: rgba(0, 0, 0, 0.02); /* Extremely subtle light gray for white theme */
  --color-bg-canvas: #424245; /* Match to primary button color */
  
  --color-text-primary: #1d1d1f; /* Near Black - for frame text */
  --color-text-secondary: #6e6e73; /* Apple-style mid-gray - for frame text */
  --color-text-tertiary: #86868b; /* Apple-style lighter gray - for frame text */
  
  /* Dark content area text */
  --color-content-text-primary: #f5f5f7; /* Off-white text for dark background */
  --color-content-text-secondary: rgba(245, 245, 247, 0.7); /* Slightly transparent white */
  
  --color-primary: #424245; /* Neutral dark gray for primary actions */
  --color-primary-hover: #515154; /* Slightly darker gray for hover states */
  --color-border-faint: #d2d2d7; /* Apple-style subtle light gray border */
  
  /* Tab Navigation Colors */
  --color-primary-accent: #3b82f6; /* Light blue for active tab */
  --color-primary-accent-light: rgba(59, 130, 246, 0.15); /* Light blue background for active tab */

  /* Typography */
  --font-primary: 'SF Pro Text', 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  --font-mono: 'SF Mono', 'Fira Code', 'Courier New', monospace;
  
  --font-size-xs: 10px;
  --font-size-sm: 12px;
  --font-size-base: 13px; /* Slightly smaller base font for Apple-like feel */
  --font-size-lg: 15px;
  --font-size-xl: 17px;
  --font-size-2xl: 19px;
  --font-size-3xl: 21px;
  
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  
  --line-height-tight: 1.2;
  --line-height-normal: 1.5;
  --line-height-relaxed: 1.6;
  
  --letter-spacing-tight: -0.5px;
  --letter-spacing-normal: -0.2px;
  --letter-spacing-wide: 0.5px;

  /* Spacing */
  --spacing-xxs: 4px;
  --spacing-xs: 8px;
  --spacing-sm: 12px;
  --spacing-md: 16px;
  --spacing-lg: 20px;
  --spacing-xl: 24px;
  --spacing-xxl: 32px;
  --spacing-xxxl: 40px;

  /* Layout */
  --header-height: 40px;
  --sidebar-width: 220px;
  --left-panel-width: 220px;
  --right-panel-width: 220px;
  
  /* Z-index */
  --z-index-header: 100;
  --z-index-modal: 1000;
  --z-index-tooltip: 1500;

  /* Animation */
  --duration-fast: 150ms;
  --duration-normal: 250ms;
  --duration-slow: 350ms;
  --easing-standard: cubic-bezier(0.4, 0, 0.2, 1);

  /* Border Radius */
  --radius-sm: 4px;
  --radius-md: 6px;
  --radius-lg: 8px;
  --radius-full: 9999px;
}
