.mobileNotice {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #232323;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  padding: 20px;
}

.mobileNoticeContainer {
  background-color: #333;
  border-radius: 8px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
  padding: 2rem;
  max-width: 90%;
  text-align: center;
  color: #fff;
}

.mobileNoticeLogo {
  width: 80px;
  height: 80px;
  margin-bottom: 1.5rem;
}

.mobileNotice h1 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
  color: #fff;
}

.mobileNotice p {
  margin-bottom: 1rem;
  line-height: 1.5;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.9);
}

.mobileNoticeDivider {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 1.5rem 0;
}

.mobileNoticeRequirements {
  text-align: left;
  margin: 1rem auto;
  padding-left: 1rem;
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.8);
}

.mobileNoticeRequirements li {
  margin-bottom: 0.5rem;
  list-style-type: disc;
}

.mobileNoticeAction {
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.mobileNoticeAction p {
  font-weight: 500;
  color: #4a9eff;
}