/* Container for the tab navigation bar */
.tabNavigationContainer {
    width: 100%;
    /* Removed fixed height to allow content to determine height */
    z-index: 20; /* Adjust z-index as needed */
    display: flex;
    justify-content: center; /* Center the inner button group */
    align-items: center;
    /* Match the background of the surrounding elements for a seamless look */
    background-color: var(--color-bg-main, #1a1a1a); /* Use a CSS variable or fallback */
    /* Remove or adjust border to blend in */
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
    border-bottom: 1px solid var(--color-border-subtle, rgba(255, 255, 255, 0.1)); /* Optional subtle border */
    padding: 0;
    margin: 0;
  }
  
  /* Inner container actually holding the buttons */
  .tabNavigation {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 0; /* Adjust vertical padding as needed */
    width: auto; /* Let buttons determine width */
  }
  
  /* Individual tab button styles */
  .tabButton {
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    padding: 8px 16px; /* Control button size */
    margin: 0 8px; /* Space between buttons */
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;
    display: flex;
    align-items: center;
    gap: 8px; /* Space between icon and text */
    border-radius: 4px;
    white-space: nowrap;
  }
  
  .tabButton:hover {
    color: rgba(255, 255, 255, 0.9);
    background-color: rgba(255, 255, 255, 0.08); /* Subtle hover background */
  }
  
  /* Active state style */
  .tabButton.active { /* Use combined selector */
    color: var(--color-primary-accent, rgba(224, 80, 70, 0.9)); /* Use theme variable */
    background-color: var(--color-primary-accent-light, rgba(224, 80, 70, 0.15)); /* Use theme variable */
    font-weight: 500; /* Indicate active state */
  }
  
  /* Optional specific styling for the icons if needed */
  .tabIcon {
    stroke: currentColor; /* Icon color matches text color */
    /* Add dimensions here if not set on SVG itself */
    width: 16px;
    height: 16px;
    flex-shrink: 0; /* Prevent icon from shrinking */
  }