.header {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--color-bg-header);
  border-bottom: 1px solid var(--color-border-faint);
}

.headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  height: var(--header-height);
}

.navigationArea {
  padding: 0 2rem;
  border-top: 1px solid var(--color-border-faint);
}

/* Header Navigation */
.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* Navigation List */
.navList {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 2rem;
}

/* Navigation Item */
.navItem {
  position: relative;
}

/* Navigation Link */
.navLink,
.navLinkActive {
  text-decoration: none;
  color: var(--color-text-secondary);
  font-weight: 500;
  padding: 0.25rem 0;
  transition: color 0.2s;
  font-size: 13px;
}

.navLink:hover {
  color: var(--color-text-primary);
}

.navLinkActive {
  color: var(--color-text-primary);
}

.navLinkActive:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--color-text-primary);
}

/* User Profile Section */
.userProfile {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.userName {
  font-weight: 500;
}

.profileMenu {
  position: relative;
}

.profileButton {
  width: 28px;
  height: 28px;
  border-radius: var(--radius-full);
  border: none;
  background: var(--color-bg-hover);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: var(--font-weight-medium);
  color: var(--color-text-secondary);
  transition: background-color var(--duration-fast) var(--easing-standard);
  font-size: 12px;
}

.profileButton:hover {
  background: var(--color-bg-active);
}

.profileButton img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.profileDropdown {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  background: var(--color-bg-secondary);
  border-radius: var(--radius-lg);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  z-index: var(--z-index-modal);
}

.dropdownItem {
  padding: 1rem;
  display: block;
  text-decoration: none;
  color: var(--color-text-primary);
  transition: background-color var(--duration-fast) var(--easing-standard);
}

.dropdownItem:hover {
  background: var(--color-bg-hover);
}

.logoutButton {
  width: 100%;
  padding: 1rem;
  border: none;
  background: none;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-primary);
  cursor: pointer;
  transition: background-color var(--duration-fast) var(--easing-standard);
}

.logoutButton:hover {
  background: var(--color-bg-hover);
}

/* Auth Buttons */
.authButtons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.loginButton {
  background-color: transparent;
  color: var(--color-text-primary);
  border: none;
  padding: 0.5rem 0;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  position: relative;
  transition: color var(--duration-fast) var(--easing-standard);
}

.loginButton:hover {
  color: var(--color-primary);
}

.loginButton:hover::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: var(--color-primary);
  transform: scaleX(1);
}

.loginButton:active {
  opacity: 0.8;
}

/* Admin Link */
.adminLink {
  text-decoration: none;
  color: var(--color-text-secondary);
  font-weight: var(--font-weight-medium);
  padding: 0.5rem 0.5rem;
  transition: color var(--duration-fast) var(--easing-standard);
  position: relative;
}

.adminLink:hover {
  color: var(--color-text-primary);
}

.adminLink:hover::after {
  content: '';
  position: absolute;
  left: 0.5rem;
  bottom: 0;
  width: calc(100% - 1rem);
  height: 1px;
  background-color: var(--color-text-primary);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navigation {
    padding: 0 var(--spacing-md);
  }

  .navList {
    gap: var(--spacing-lg);
  }

  .userName {
    display: none;
  }
}

@media (max-width: 480px) {
  .navList {
    gap: var(--spacing-md);
  }

  .navLink {
    padding: var(--spacing-xxs) var(--spacing-xs);
    font-size: var(--font-size-sm);
  }
}
