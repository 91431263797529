.documentationView {
  width: 100%;
  height: 100%;
  padding: 2rem;
  overflow-y: auto;
  background-color: inherit;
  color: var(--color-content-text-primary);
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, sans-serif;
}

/* Report Header Styles */
.reportHeader {
  max-width: 800px;
  margin: 0 auto 2rem;
  padding: 1rem 0 1.5rem;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: space-between;
}

.reportTitle h1 {
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
  color: var(--color-content-text-primary);
  letter-spacing: 0.5px;
}

.reportMeta {
  font-size: 0.9rem;
  color: var(--color-content-text-secondary);
  line-height: 1.4;
}

.reportIds {
  font-size: 0.85rem;
  line-height: 1.4;
  color: var(--color-content-text-secondary);
  text-align: right;
  max-width: 250px;
}

/* Report Content Styles */
.reportContent {
  margin-bottom: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

/* Report Footer Styles */
.reportFooter {
  max-width: 800px;
  margin: 2rem auto 0;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 0.85rem;
}

.reportEnd {
  text-align: center;
  font-weight: 600;
  color: var(--color-content-text-secondary);
  margin-bottom: 1rem;
  letter-spacing: 1px;
}

.reportDisclaimer {
  color: rgba(255, 255, 255, 0.5);
  font-style: italic;
  line-height: 1.5;
  text-align: center;
  font-size: 0.8rem;
  max-width: 600px;
  margin: 0 auto;
}

.sectionTitle {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: var(--color-content-text-primary);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 0.5rem;
}

.responseLine {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 0.75rem;
  color: var(--color-content-text-secondary);
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

/* Style for bullet points and list items in the raw text */
.responseLine {
  position: relative;
}

/* Style for list items */
.listItem {
  padding-left: 1.5rem;
}

/* Style for bullet points */
.bulletPoint::before {
  content: "•";
  position: absolute;
  left: 0.5rem;
  color: rgba(255, 255, 255, 0.6);
}

/* Action Buttons */
.actionButtons {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.actionButton {
  padding: 0.5rem 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.05);
  color: var(--color-content-text-secondary);
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.actionButton:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.3);
}

.feedbackPositive {
  color: #81c784;
  border-color: #81c784;
}

.feedbackPositive:hover {
  background: rgba(129, 199, 132, 0.1);
}

.feedbackNegative {
  color: #e57373;
  border-color: #e57373;
}

.feedbackNegative:hover {
  background: rgba(229, 115, 115, 0.1);
}

/* Feedback Modal */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.feedbackModal {
  position: relative;
  background: #2a2a2c;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  max-width: 500px;
  width: 90%;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.modalTitle {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: var(--color-content-text-primary);
}

.issueSelection {
  margin-bottom: 1.5rem;
}

.issueItem {
  margin-bottom: 1rem;
}

.issueLabel {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  color: var(--color-content-text-secondary);
  margin-bottom: 0.5rem;
}

.issueComment,
.feedbackComment {
  width: 100%;
  min-height: 80px;
  padding: 0.75rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  font-size: 0.9rem;
  line-height: 1.4;
  resize: vertical;
  margin-top: 0.5rem;
  background-color: rgba(255, 255, 255, 0.05);
  color: var(--color-content-text-primary);
}

.issueComment:focus,
.feedbackComment:focus {
  outline: none;
  border-color: #2196f3;
  background-color: rgba(255, 255, 255, 0.1);
}

.modalActions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.modalCancel,
.modalSubmit {
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.modalCancel {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: var(--color-content-text-secondary);
}

.modalCancel:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.3);
}

.modalSubmit {
  background: #2196f3;
  border: 1px solid #2196f3;
  color: white;
}

.modalSubmit:hover {
  background: #1976d2;
  border-color: #1976d2;
}

.modalSubmit:disabled {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.3);
  cursor: not-allowed;
}

.feedbackSuccess {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(42, 42, 44, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: #81c784;
  border-radius: 8px;
} 