/* Reset default styles */
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Reset HTML and body */
html, body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

/* Reset lists */
ul, ol {
  list-style: none;
}

/* Reset buttons */
button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font: inherit;
  color: inherit;
}

/* Reset links */
a {
  color: inherit;
  text-decoration: none;
}

/* Reset inputs */
input, textarea, select {
  font: inherit;
  color: inherit;
  border: none;
  background: none;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

/* Reset images */
img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Reset headings */
h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

/* Reset form elements */
fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

/* Reset tables */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Ensure root element fills viewport */
#root {
  height: 100%;
  isolation: isolate;
}