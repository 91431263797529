.canvasViewManager {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background-color: var(--color-bg-primary);
  color: var(--color-content-text-primary);
}

.viewContainer {
  flex: 1;
  overflow: auto;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 1.2rem;
  color: var(--color-content-text-secondary);
}
