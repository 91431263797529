.footer {
  width: 100%;
  height: 100%;
  background-color: var(--color-bg-secondary);
  border-top: 1px solid var(--color-border-faint);
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-xl);
  color: var(--color-text-primary);
}

.content {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}
