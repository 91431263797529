.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
}

.research-preview-banner {
  margin-bottom: 1.5rem;
  text-align: center;
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.loading-animation {
  margin-bottom: 1.5rem;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.logo-svg {
  width: 100%;
  height: 100%;
}

/* Line animation for the SVG paths */
.svg-path {
  fill: none;
  stroke: white;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 1500;
  stroke-dashoffset: 1500;
  animation: draw-line 4s cubic-bezier(0.5, 0, 0.5, 1) forwards infinite;
}

/* Multiple paths with different delays for a continuous drawing effect */
.svg-path:nth-child(1) {
  animation-delay: 0s;
}

.svg-path:nth-child(2) {
  animation-delay: 1s;
  stroke-width: 1.2;
}

.svg-path:nth-child(3) {
  animation-delay: 2s;
  stroke-width: 1;
}

.svg-path:nth-child(4) {
  animation-delay: 3s;
  stroke-width: 1;
}

/* Enhanced glowing effect */
.svg-glow {
  filter: drop-shadow(0 0 3px rgba(255, 255, 255, 0.8));
}

.loading-message {
  color: white;
  text-align: center;
  font-size: 1rem;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.loading-message.hidden {
  opacity: 0;
}

.loading-message.visible {
  opacity: 1;
}

/* Animation for drawing SVG paths */
@keyframes draw-line {
  0% {
    stroke-dashoffset: 1500;
  }
  40% {
    stroke-dashoffset: 0;
  }
  60% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -1500;
  }
}
