.leftPanel {
  height: 100%;
  background-color: var(--color-bg-secondary);
  border-right: 1px solid var(--color-border-faint);
  overflow-y: auto;
  /* Added line to force update */
  text-transform: none;
}

.titleSection {
  padding: 16px 15px 15px 15px;
  border-bottom: 1px solid var(--color-border-faint);
  display: flex;
  flex-direction: column;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 100%;
  text-align: left;
}

.titleContainer h1 {
  font-size: 8px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 0;
  margin-top: 0;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  line-height: 1.2;
}

.researchPreview {
  font-size: 8px;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 0;
  margin-top: 0;
  white-space: nowrap;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 1.2;
}

.proprietyInfo {
  font-size: 8px;
  color: rgba(0, 0, 0, 0.7);
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0;
  max-width: 100%;
  text-align: left;
  letter-spacing: 0.1px;
}

.contentSection {
  padding: 24px 20px;
}

.uploadSection h3 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: var(--spacing-md);
  color: var(--color-text-primary);
  letter-spacing: -0.2px;
}

.uploadArea {
  border: 1px dashed var(--color-border-faint);
  border-radius: var(--radius-lg);
  padding: var(--spacing-xl) var(--spacing-md);
  text-align: center;
  cursor: pointer;
  transition: all var(--duration-fast) var(--easing-standard);
}

.uploadArea:hover {
  border-color: var(--color-text-secondary);
}

.dragover {
  border-color: var(--color-text-primary);
}

.hasThumbnail {
  padding: var(--spacing-md);
}

.thumbnailContainer {
  width: 100%;
  max-height: 200px;
  overflow: hidden;
  border-radius: var(--radius-md);
}

.thumbnailImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.questionSection {
  margin-top: 16px;
}

.questionSection h3 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 8px;
  color: var(--color-text-primary);
  letter-spacing: -0.2px;
}

.promptExamples {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
}

.promptExamplesLabel {
  font-size: var(--font-size-sm);
  color: var(--color-text-secondary);
  margin-bottom: 2px;
}

.promptButton {
  padding: 8px 14px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  transition: all var(--duration-fast) var(--easing-standard);
  color: var(--color-text-secondary);
  font-size: 13px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.03);
}

.promptButtonContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.completionBadge {
  background-color: var(--color-primary);
  color: white;
  border-radius: 10px;
  padding: 2px 6px;
  font-size: 10px;
  font-weight: 500;
}

.promptButton:hover {
  color: var(--color-text-primary);
  border-color: rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.07);
}

.promptButton.selected {
  color: var(--color-text-primary);
  font-weight: var(--font-weight-medium);
  border-color: var(--color-primary);
  background-color: rgba(220, 80, 41, 0.05);
}

.actionButtonContainer {
  margin-top: var(--spacing-xl);
}

.actionBtn {
  width: auto;
  padding: var(--spacing-md) var(--spacing-xl);
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--radius-full);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--duration-fast) var(--easing-standard);
  font-size: var(--font-size-sm);
  letter-spacing: 0.5px;
  display: block;
  margin: 0 auto;
}

.actionBtn:hover:not(:disabled) {
  background-color: var(--color-primary-hover);
  transform: translateY(-1px);
}

.actionBtn:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.activateBtn {
  font-size: var(--font-size-sm);
  margin-top: var(--spacing-lg);
  padding: var(--spacing-xs) 0;
  background-color: transparent;
  color: var(--color-text-secondary);
  border: none;
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--duration-fast) var(--easing-standard);
  text-align: center;
  display: block;
  position: relative;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}

.activateBtn:hover {
  color: var(--color-text-primary);
}

.activateBtn:hover::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: var(--color-text-primary);
}

/* Advanced Diagnostics styles */
.advancedSummary {
  margin-top: 14px;
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 12px 14px;
}

.advancedSummary h4 {
  font-size: 13px;
  font-weight: 500;
  margin: 0 0 8px 0;
  color: var(--color-text-primary);
}

.advancedSummary ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.advancedSummary li {
  font-size: 12px;
  color: var(--color-text-secondary);
  margin-bottom: 4px;
  position: relative;
  padding-left: 16px;
}

.advancedSummary li:before {
  content: '✓';
  position: absolute;
  left: 0;
  color: var(--color-primary);
}

.editButton {
  background: transparent;
  border: none;
  color: var(--color-primary);
  font-size: 12px;
  cursor: pointer;
  padding: 4px 0;
  margin-top: 6px;
  font-weight: 500;
  transition: all var(--duration-fast) var(--easing-standard);
}

.editButton:hover {
  color: var(--color-primary-hover);
  text-decoration: underline;
}
